import '../styles/global.scss';
import '../styles/pages/trial.scss';
import {BigLogoImage, serv1, serv2, serv3, serv4, serv5, faq, adva2, adva3, adva4, adva5, freeFrame, Star, Sign, Shield} from "../images";

import {HeadFC, Link} from "gatsby";

import React, { useEffect, useState } from 'react'
import TimerCTA from '../components/TimerCTA';
// import {fbq} from "@hutsoninc/gatsby-plugin-facebook-pixel";

const AppstoreTrial = () => {

    const [eventStatus, setEventStatus] = useState(false);

    useEffect( () => {
        if(!eventStatus){
            // fbq("track", "AddToCart");
            console.log('fb track AddToCart');
            setEventStatus(true);
        }
    }, [eventStatus]);
    

    return (
        <>
            <div className="MobileWrap">
                <div className="Trial">
                    <div className="containerSpec">
                        <div className="TrialBannerBox">
                            <a href="" className="TrialBannerLogo">
                                <img src={BigLogoImage} alt="" />
                            </a>
                            <h1 className="TrialBannerTitle">
                                <span>Education</span> and <span>Productivity</span><br/> in one Platform
                            </h1>
                        </div>
                    </div>
                    
                    <div className="TrialServ">
                        <div className="containerSpec">
                            <div className="TrialServBox">
                                <h2 className="TrialServTitle">We offer:</h2>
                                <div className="TrialServItem flex">
                                    <img src={serv1} alt="" className="TrialServIcon" />
                                    <div className="TrialServInfo">
                                        <span>AEER Platform</span>
                                        <p>Valuable tools in one platform available on IOS,Android, Web</p>
                                    </div>
                                </div>
                                <div className="TrialServItem flex">
                                    <img src={serv2} alt="" className="TrialServIcon" />
                                    <div className="TrialServInfo">
                                        <span>AEER - Education </span>
                                        <p>Access to 50+ programs for soft skills development </p>
                                    </div>
                                </div>
                                <div className="TrialServItem flex">
                                    <img src={serv3} alt="" className="TrialServIcon" />
                                    <div className="TrialServInfo">
                                        <span>AEER - Finances </span>
                                        <p>Program for managing personal finances</p>
                                    </div>
                                </div>
                                <div className="TrialServItem flex">
                                    <img src={serv4} alt="" className="TrialServIcon" />
                                    <div className="TrialServInfo">
                                        <span>AEER - Tasks and Goals </span>
                                        <p>Set tasks and goals by one click </p>
                                    </div>
                                </div>
                                <div className="TrialServItem flex">
                                    <img src={serv5} alt="" className="TrialServIcon" />
                                    <div className="TrialServInfo">
                                        <span>AEER - Dairy</span>
                                        <p>Note important decisions and actions into private business diary</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="TrialFaq">
                        <div className="containerSpec">
                            <div className="TrialFaqBox">
                                <h2 className="TrialFaqTitle">
                                    <div className="TrialFaqItem">
                                        <img src={faq} alt="" className="TrialFaqIcon" />
                                        <div className="TrialFaqInfo">
                                            <span>AEER PLATFORM - is ?</span>
                                            <p>
                                                An innovative platform that combines education and productivity with the most convenient interface that supports WEB, iOS and Android. <br />
                                                An integrated approach to managing major areas of life and developing important skills for better results
                                            </p>
                                        </div>
                                    </div>
                                    <div className="TrialFaqItem">
                                        <img src={faq} alt="" className="TrialFaqIcon" />
                                        <div className="TrialFaqInfo">
                                            <span>I don’t have time for self-education</span>
                                            <p>Our programs are offered  in a live dialogue mode, you can study in short 3-5 minute intervals in your spare time, for example, on your way to work, in a taxi, subway or while waiting for an order in a restaurant</p>
                                        </div>
                                    </div>
                                    <div className="TrialFaqItem">
                                        <img src={faq} alt="" className="TrialFaqIcon" />
                                        <div className="TrialFaqInfo">
                                            <span>How effectively your educational programs?</span>
                                            <p>Leading experts from relevant industries took part in the creation of the program. Via  the interactive system, you will get a fast, high-quality experience for solving real problems and gaining practical knowledge.</p>
                                        </div>
                                    </div>
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div className="TrialAdva">
                        <div className="containerSpec">
                            <div className="TrialAdvaBox">
                                <h2 className="TrialAdvaTitle">YOU WILL GET:</h2>
                                <div className="TrialAdvaItem">
                                    <img src={adva2} alt="" className="TrialAdvaIcon" />
                                    <div className="TrialAdvaInfo">
                                        <span>Valuable skills</span>
                                        <p>Our courses will give you the opportunity to quickly realize yourself in different areas of life.</p>
                                    </div>
                                </div>
                                <div className="TrialAdvaItem">
                                    <img src={adva3} alt="" className="TrialAdvaIcon" />
                                    <div className="TrialAdvaInfo">
                                        <span>Tool of financial literacy </span>
                                        <p>Ability to control expenses and income, planning your budget</p>
                                    </div>
                                </div>
                                <div className="TrialAdvaItem">
                                    <img src={adva4} alt="" className="TrialAdvaIcon" />
                                    <div className="TrialAdvaInfo">
                                        <span>Goal and task management tool</span>
                                        <p>Handy interface allows you to instantly add tasks and set goals for the future</p>
                                    </div>
                                </div>
                                <div className="TrialAdvaItem">
                                    <img src={adva5} alt="" className="TrialAdvaIcon" />
                                    <div className="TrialAdvaInfo">
                                        <span>Tool to record and analyze your actions</span>
                                        <p>Personal diary for business, educational and work records</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="TrialPeriod">
                        <div className="containerSpec">
                            <div className="TrialPeriodBox">
                                <div className="TrialPeriodLeft">
                                    <span>7-days trial period</span>
                                </div>
                                <div className="TrialPeriodRight">
                                    <img src={freeFrame} alt="" />
                                </div>
                            </div>
                            <div className="TrialPeriodCenter">
                                <Link to="/payment" className="TrialPeriodBtn">GET FREE ACCESS</Link>
                            </div>
                        </div>
                    </div>
                    <div className="TrialFeed">
                        <div className="containerSpec">
                            <h2 className="TrialFeedTitle">User love our app</h2>
                            <div className="TrialFeedItem">
                                <div className="TrialFeedHeading">
                                    <div className="TrialFeedLeft">
                                        <span>Thank you so much!</span>
                                        <div className="TrialFeedStars">
                                            <img src={Star} alt="" />
                                            <img src={Star} alt="" />
                                            <img src={Star} alt="" />
                                            <img src={Star} alt="" />
                                            <img src={Star} alt="" />
                                        </div>
                                    </div>
                                    <div className="TrialFeedRight">
                                        <span>25 Jul</span>
                                        <p>Alex</p>
                                    </div>
                                </div>
                                <div className="TrialFeedText">
                                    <p>It was important for me to use my spare time for self-education. Thanks to AEER, I’m studying every free minute!</p>
                                </div>
                            </div>
                            <div className="TrialFeedItem">
                                <div className="TrialFeedHeading">
                                    <div className="TrialFeedLeft">
                                        <span>The APP helped me</span>
                                        <div className="TrialFeedStars">
                                            <img src={Star} alt="" />
                                            <img src={Star} alt="" />
                                            <img src={Star} alt="" />
                                            <img src={Star} alt="" />
                                            <img src={Star} alt="" />
                                        </div>
                                    </div>
                                    <div className="TrialFeedRight">
                                        <span>27 Jul</span>
                                        <p>Jhon</p>
                                    </div>
                                </div>
                                <div className="TrialFeedText">
                                    <p>I work in IT. This application has collected the functions that are important for me. Here I manage my tasks, finances, write down my thoughts. I plan to start learning their programs</p>    
                                </div>
                            </div>
                            <div className="TrialFeedItem">
                                <div className="TrialFeedHeading">
                                    <div className="TrialFeedLeft">
                                        <span>Took a course, like it</span>
                                        <div className="TrialFeedStars">
                                            <img src={Star} alt="" />
                                            <img src={Star} alt="" />
                                            <img src={Star} alt="" />
                                            <img src={Star} alt="" />
                                            <img src={Star} alt="" />
                                        </div>
                                    </div>
                                    <div className="TrialFeedRight">
                                        <span>3 Aug</span>
                                        <p>Jesika</p>
                                    </div>
                                </div>
                                <div className="TrialFeedText">
                                    <p>I didn't know how to ask my boss for a raise. The course provided me how to approach this issue correctly. I decided not only the salary issue, but also other points of interest to me at work</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="TrialAccess">
                        <div className="containerSpec">
                            <div className="TrialAccessBox">
                                <div className="TrialAccessHead">
                                    <img src={Shield} alt="" />
                                    <span>30-Day Money-Back Guarantee</span>
                                </div>
                                <div className="TrialAccessText">
                                    <p>We believe that our plan may work for you and you’ll get visible results in 4 weeks! We even are ready to return your money back if you don’t see visible results and can demonstrate that you followed our plan. Find more about applicable limitations in our <a href="">money-back policy</a> </p>
                                </div>
                                <img src={Sign} alt="" className="TrialAccessSign" />
                            </div>
                            <Link to="/payment" className="TrialPeriodBtn TrialAccessBtn">GET FREE ACCESS</Link>
                        </div>
                    </div>
                    <div className="TrialFooter">
                        <div className="containerSpec">
                            <p className="TrialFooterText">Your free trial starts today and ends on 10.11.2022. Then you will be charged $0.48 per day.  No compromises. You can cancel at any time.</p>
                            <p className="TrialFooterCopy">© 2022 AEER Platform 8 THE GREEN STE A DOVER, DE 19901</p>
                        </div>
                    </div>
                    <div className="AppstoreSpace"></div>
                    <TimerCTA timerVal="2:00" link="/payment" />
                </div>
            </div>
        </>
    )
}

export default AppstoreTrial;

export const Head: HeadFC = () => (
    <>
        <title>Upgrade yourself - AEER PLATFORM</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"/>
    </>
);
