import './styles.scss';
import React, {memo, useEffect, useState} from 'react';
import { Link } from 'gatsby';

type Props = {
    timerVal: string,
    link: string,
}

const TimerCTA = ({timerVal, link}: Props) => {

    const [timer, setTimer] = useState(timerVal);

    const startTimer = () => {
        let presentTime = timer;
        let timeArray = presentTime.split(/[:]+/);
        let m = timeArray[0];
        let s = checkSecond((timeArray[1] - 1));
        if(s == 59){ m = m - 1 }
        if(m < 0){
          return
        }
        const result = m + ":" + s;
        setTimer(result);
    }

    const checkSecond = (sec) => {
        if (sec < 10 && sec >= 0) {sec = "0" + sec};
        if (sec < 0) {sec = "59"};
        return sec;
    }

    useEffect(() => {
        if(timerVal.length !== 0){
            const interval = setInterval(() => startTimer(), 1000);
            return () => clearInterval(interval);
        }
    }, [timer]);

    return (
        <>
        
            {
                    (timer.length !== 0) ? (
                        <div className="TimerCTA">
                            <div className="TimerCTACountdown">{timer}</div>
                            <Link to={link} className="TimerCTAButton">TRY IT FOR FREE</Link>
                        </div>
                    ) : (
                        <div className="TimerCTA">
                            <Link to={link} className="TimerCTAButton full">TRY IT FOR FREE</Link>
                        </div>
                    )
            }
            
        </>
    )
}

export default memo(TimerCTA);
